import './home.css';

import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

import TransactionsList from '../../components/transactionslist/transactionslist';
import MakePayment from '../../components/makepayment/makepayment';
import Partners from '../../components/partners/partners';

import { companyDataFetchStart, companyDataFetchSuccess, companyDataFetchFailure } from '../../redux/companySlice';

export default function Home() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [company_sent_data, setCompanyData] = useState([]);
    const [loadCompanyData, setLoadCompanyData] = useState("true");

    const host_company_data = useSelector(companyDataFetchSuccess);

    const [transactions_data, setTransactionsData] = useState([]);
    const [loadTransactionsData, setLoadTransactionsData] = useState("true");

    const loggedUser = useSelector(state=>state.user.user);

    if(!loggedUser) {
        window.location.href = "/connection";
        toast.info("Veuillez vous connecter !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
    }

    useEffect(()=>{ 

        const getCompanyData = async()=>{

            try{

                const companyRes = await axios.get(process.env.REACT_APP_API_KEY+"/company/?host_organization=true");
                setCompanyData(companyRes.data);

                setLoadCompanyData("false");
            }
            catch(err) {
                setLoadCompanyData("false");
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            
        }

        /* if (Object.keys(host_company_data.payload.companyData.companyData).length === 0) {
            getCompanyData();          
        }
        else
        {
            setCompanyData(host_company_data.payload.companyData.companyData);

            setLoadCompanyData("false");
        }
        */

    }, []);

    /*
    const search = () => {

        if((experts_search_term == '')&&(database_search_term == ''))
        {
            toast.warning('Aucun élément à rechercher. Veuillez saisir au moins un terme de recherche pour continuer !', { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            navigate('/recherche/?expert='+experts_search_term+'&base_de_donnee='+database_search_term);
        }

    }
    */
    
    return(
        <>
            <MakePayment />
        </>
    )
}
