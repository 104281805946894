import './edituser.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

export default function EditUser() {

    const queryParams = new URLSearchParams(window.location.search);
    const param = queryParams.get('param');

    const loggedUser = useSelector(state=>state.user.user);

    const [ id, setUserId ] = useState("");
    const [ title, setTitle ] = useState("");
    const [ first_name, setFirstName ] = useState("");
    const [ last_name, setLastName ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ tel, setTel ] = useState("");
    const [ facebook_profile, setFacebookProfile ] = useState("");
    const [ twitter_profile, setTwitterProfile ] = useState("");
    const [ linkedin_profile, setLinkedinProfile ] = useState("");
    const [ profile_image, setUploadedFilePath ] = useState("");
    const [ short_bio, setShortBio ] = useState("");

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    useEffect(()=> {

        setUserId(param);
        setEditorLoaded(true);

        const getUserData = async()=> {
            const user_data = await axios.get(process.env.REACT_APP_API_KEY+'/users/'+param);

            setTitle(user_data.data.title);
            setFirstName(user_data.data.first_name);
            setLastName(user_data.data.last_name);
            setEmail(user_data.data.email);
            setTel(user_data.data.tel);
            setUploadedFilePath(user_data.data.profile_image);
            setFacebookProfile(user_data.data.facebook_profile);
            setTwitterProfile(user_data.data.twitter_profile);
            setLinkedinProfile(user_data.data.linkedin_profile);
            setShortBio(user_data.data.short_bio);
        }

        // functions call

        getUserData();

    }, []);

    const handleFileUpload = async(event) => {

        const current_file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', current_file);

        try {
            const fileUploadData = await axios.post(process.env.REACT_APP_API_KEY+'/law-database/file-upload/', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            setUploadedFilePath(fileUploadData.data.uploaded_file);
        } catch (error) {
            toast.error("Erreur lors du téléchargement du fichier : "+error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    };

    const postData = async(e) => {
        e.preventDefault();

        if((title == "")||(first_name == "")||(last_name == "")||(email == "")||(tel == "")||(short_bio == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            setLoader(true);

            try{

                const data_post = await axios.post(process.env.REACT_APP_API_KEY+"/users/"+param, {id, title, first_name, last_name, email, tel, facebook_profile, twitter_profile, linkedin_profile, profile_image, short_bio}, {
                        headers: {
                            Authorization: loggedUser.token
                        }
                });

                setTitle(data_post.data.title);
                setFirstName(data_post.data.first_name);
                setLastName(data_post.data.last_name);
                setEmail(data_post.data.email);
                setTel(data_post.data.tel);
                setFacebookProfile(data_post.data.facebook_profile);
                setTwitterProfile(data_post.data.twitter_profile);
                setLinkedinProfile(data_post.data.linkedin_profile);
                setShortBio(data_post.data.short_bio);

                setLoader(false);

                toast.success("Profil mis à jour avec succès !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser ?

            	<section aria-label="section">

                    {loggedUser.slug === param || loggedUser.is_admin === true ? 

                        <div className="container">
                            <div className="row">

                                <div className="col-md-8">

                                    <h4 className="form-title">Editer </h4>
                                    <br/>

                                    <div className="form-card">
                                        <label>Titre <span className="req">*</span></label>
                                        <input type="text" className="input" value={ title } onChange={ e=>setTitle(e.target.value) } />
                                        <label>Nom <span className="req">*</span></label>
                                        <input type="text" className="input" value={ first_name } onChange={ e=>setFirstName(e.target.value) } />
                                        <label>Prénom <span className="req">*</span></label>
                                        <input type="text" className="input" value={ last_name } onChange={ e=>setLastName(e.target.value) } />
                                        <label>Email <span className="req">*</span></label>
                                        <input type="email" className="input" value={ email } onChange={ e=>setEmail(e.target.value) } />
                                        <label>Tel <span className="req">*</span></label>
                                        <input type="text" className="input" value={ tel } onChange={ e=>setTel(e.target.value) } />
                                        <label>Lien vers le profil Facebook </label>
                                        <input type="text" className="input" value={ facebook_profile } onChange={ e=>setFacebookProfile(e.target.value) } />
                                        <label>Lien vers le profil Twitter </label>
                                        <input type="text" className="input" value={ twitter_profile } onChange={ e=>setTwitterProfile(e.target.value) } />
                                        <label>Lien vers le profil Facebook </label>
                                        <input type="text" className="input" value={ linkedin_profile } onChange={ e=>setLinkedinProfile(e.target.value) } />
                                        <label>Image de profil </label>
                                        <input type="file" className="input" onChange={ handleFileUpload } />
                                        <br/>
                                        <label>Biographie <span className="req">*</span></label>
                                        {/*<textarea cols="10" rows="10" className="area-input" value={ short_bio }  onChange={ e=>setShortBio(e.target.value) } ></textarea>*/}
                                        
                                        <CKeditor
                                            name="short_bio"
                                            onChange={(short_bio) => {
                                              setShortBio(short_bio);
                                            }} value={short_bio}
                                            editorLoaded={editorLoaded}
                                        />
                                        <br/>

                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="50"
                                                    width="50"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </a> : <button className="cmn-btn" onClick={ postData }>Mettre à jour</button>   
                                            
                                        }

                                    </div>

                                </div>
                                
                            </div>
                        </div>

                    :

                        <div className="kode-content">
                            <div className="kode-about-us-section">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-6 success-message">
                                            Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        
                    }

                </section>   

            :

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                
            }

        </>

    )
}