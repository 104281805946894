import './users.css';

import axios from 'axios';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { LineWave } from  'react-loader-spinner';

import UserCpnt from '../../components/users/users';

export default function Users() {

	const queryParams = new URLSearchParams(window.location.search);

	const domain = queryParams.get('domain');
	const experts = queryParams.get('expert');

    const dispatch = useDispatch();

    const [expertise_data, setExpertiseData] = useState([]);
    const [search_term, setSearchTerm] = useState("");
    const [expertise_search, setExpertiseSearch] = useState("");
    const [load, setLoader] = useState('');

    useEffect(()=>{

        window.scrollTo(0, 0);

        const getExpertiseData = async()=>{
            const expertiseRes = await axios.get(process.env.REACT_APP_API_KEY+"/expertise/");
            setExpertiseData(expertiseRes.data);
        }

    }, []);

    const userSearch = async(e) => {
        e.preventDefault();

        setLoader(true);

        try{
            setSearchTerm("");
            setExpertiseSearch("");
            setLoader(false);
        }
        catch(err) {
            setLoader(false);
            toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
        }

    }

	return(
		<>
            <div className="top-section">.</div>
            <section aria-label="section">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12 text-center">
                            <h2><br/>Nos Experts<br/><br/></h2>
                            {/*<div className="small-border"></div>*/}
                        </div>
                        <div id="sidebar" className="col-md-3">
                            <div className="widget widget-post">
                                <h4>Filtrer</h4>
                                <div className="small-border"></div>
                                <div className="user-search-card">
                                    <br/><br/>
                                    <input type="text" value={search_term} onChange={e=>setSearchTerm(e.target.value)} placeholder="Rechercher ..." />
                                    <input type="text" placeholder="Lieu" />
                                    <select value={expertise_search} onChange={e=>setExpertiseSearch(e.target.value)}>
                                        <option value=""> Domaine d'expertise </option>
                                        {expertise_data?.map((expertise)=>(
                                            <option value={expertise._id}> {expertise.name} </option>
                                        ))}                           
                                    </select>

                                    {load === true ?
                                        <a className="newsletter-registration-load">
                                            <LineWave  
                                                        height="100"
                                                        width="100"
                                                        color="#4fa94d"
                                                        ariaLabel="line-wave"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                        firstLineColor=""
                                                        middleLineColor=""
                                                        lastLineColor=""
                                            />
                                        </a> : <button className="search-button" onClick={userSearch}>Chercher</button>
                                    
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <UserCpnt expert={experts} domain={domain} search_domain={expertise_search} search_term={search_term} />
                        </div>
                    </div>
                </div>
            </section>

		</>
	)

}