import './transactiondata.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function TransactionData({ transaction_status, transaction_id, update_payment }) {

	const [ transactionData, setTransactionData ] = useState([]);
    const [ retrieved_transaction_id, setRetrievedTransactionID ] = useState("");
    const [ transactionMadeByAdminDetails, setTransactionMadeByAdminDetails ] = useState("");
    const [ loadTransaction, setLoadTransaction ] = useState("true");
    const [ showNoData, setShowNoData ] = useState("false");

	const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getTransactionData = async()=> {

			try{
				const transaction_data = await axios.get(process.env.REACT_APP_API_KEY+'/transaction/'+transaction_id+'/?status='+transaction_status+'&update_payment='+update_payment, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });
				
				setTransactionData(Array.isArray(transaction_data.data) ? transaction_data.data : [transaction_data.data]);

				setRetrievedTransactionID(transaction_data.data[0] ? transaction_data.data[0]._id : transaction_data.data._id);

				setLoadTransaction("false");
			} catch(err) {
				setShowNoData("true");
				toast.error(err.response ? err.response.data : err, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getTransactionData();

	}, []);

	const updateStatus = (type, actionMessage)=> {

		Swal.fire({
		  title: 'Mettre à jour',
		  text: actionMessage,
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonColor: '#000',
		  cancelButtonColor: '#d33',
		  confirmButtonText: 'Oui !',
		  cancelButtonText: 'Non !'
		}).then((result) => {
		  if (result.isConfirmed) {

		    processUpdateStatus(type);

		  }
		})

	}

	const processUpdateStatus = async (type) => {
	    try {
	        toast.info("Opération en cours !", {
	            position: toast.POSITION.TOP_RIGHT,
	            autoClose: 5000,
	            toastId: 'toast2'
	        });

	        let data = {};

	        if (type === 'moneySentByAdmin') {
				if(transactionMadeByAdminDetails === '') {
					toast.error("Veuillez entrer les détails de la transaction !", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 15000,
						toastId: 'toast6'
					});

					return;
				}
	            data = {
					transactionMadeByAdmin: true,
	                transactionType: 'moneySentByAdmin',
	                transactionMadeByAdminOn: new Date().toISOString(),
					transactionMadeByAdminDetails: transactionMadeByAdminDetails
	            };
	        } else if (type === 'moneyWithdrawn') {
	            data = {
	                transactionWithdrawn: true,
	                transactionType: 'moneyWithdrawn',
	                transactionWithdrawnOnDateTime: new Date().toISOString()
	            };
	        } else {
	            toast.error("Invalid operation type ", {
		            position: toast.POSITION.TOP_RIGHT,
		            autoClose: 15000,
		            toastId: 'toast4'
		        });
	        }

	        const updated_transaction_data = await axios.post(
	            `${process.env.REACT_APP_API_KEY}/transaction/${retrieved_transaction_id}`,
	            data,
	            {
	                headers: {
	                    Authorization: loggedUser.token
	                }
	            }
	        );

	        toast.success("Status mis à jour avec succès !", {
	            position: toast.POSITION.TOP_RIGHT,
	            autoClose: 15000,
	            toastId: 'toast5'
	        });

	        setTransactionData([updated_transaction_data.data]);
	    } catch (err) {
	        toast.error("Une erreur est survenue : " + (err.response ? err.response.data.message : err.message), {
	            position: toast.POSITION.TOP_RIGHT,
	            autoClose: 15000,
	            toastId: 'toast6'
	        });
	    }
	};

	return (

		<>
			{loadTransaction === 'true' ? 
                <div className="data-loader">
                    <TailSpin 
                        height="40"
                        width="40"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperclass=""
                        visible={true}
                    />
                </div>

                : "" 
            }

			{showNoData === 'true' ? <div className="no-data">Pas de donnée trouvée !</div> : 

				<>
					{Array.isArray(transactionData) && transactionData.map((transaction) => (
						<>
							{transaction.transactionSuccessful ? 
								<div className="money-sent-by-user">
									Argent envoyé par l'expéditeur le&nbsp;

									{new Date(transaction.transactionCompletedOn).toLocaleString('fr-FR', {
					                    year: 'numeric',
					                    month: 'long',
					                    day: 'numeric',
					                    hour: 'numeric',
					                    minute: 'numeric',
					                    second: 'numeric'
					                })}

								</div> 
							: 
								<div id="container" className="margin-bottom">
								  <div id="error-box">
								    <div className="dot"></div>
								    <div className="dot two"></div>
								    <div className="face2">
								      <div className="eye"></div>
								      <div className="eye right"></div>
								      <div className="mouth sad"></div>
								    </div>
								    <div className="shadow move"></div>
								    <div className="message"><h1 className="alert error-info-text">Error!</h1><div className="not-sent-error-message">Oh non, une erreur s'est produite lors de l'envoi de l'argent.</div></div>
								    <a href="{transaction.paymentURL}"><button className="button-box">Réessayer</button></a>
								  </div>
								</div>
							}

							{transaction.transactionWithdrawn ? 
								<div className="money-withdrawn-by-user">
									Argent perçu le&nbsp;
									{new Date(transaction.transactionWithdrawnOnDateTime).toLocaleString('fr-FR', {
					                    year: 'numeric',
					                    month: 'long',
					                    day: 'numeric',
					                    hour: 'numeric',
					                    minute: 'numeric',
					                    second: 'numeric'
					                })}
								</div> 
							: 
								<div className="money-not-withdrawn-by-user">
									Argent pas encore perçu
								</div> 
							}

							<h4 className="title medium-title text-white">CODE: {transaction.transactionCode}</h4>
							
							<div className="col-md-6">
								<div className="tab-content">
				                    <div className="tab-pane fade show active" id="about-paylio" role="tabpanel" aria-labelledby="about-paylio-tab">
				                    	<div className="right-item">
				                    		<div className="accordion transaction-details" id="accordionAbout-paylio">
				                    			<h6 className="section-title text-white">Expéditeur</h6>
				                    			
				                    			<h6 className="text-white">Nom</h6>
				                    			<p className="text-white"><b>{transaction.sender.first_name}</b></p>
				                    			<h6 className="text-white">Prénom</h6>
				                    			<p className="text-white"><b>{transaction.sender.last_name}</b></p>
				                    			<h6 className="text-white">Téléphone de l'expéditeur</h6>
				                    			<p className="text-white"><b>{transaction.sender.tel}</b></p>
				                    			<h6 className="text-white">Adresse e-mail de l'expéditeur</h6>
				                    			<p className="text-white"><b>{transaction.sender.email}</b></p>
				                    			<h6 className="text-white">Pays d'expédition</h6>
				                    			<p className="text-white"><b>{transaction.sendingCountry}</b></p>
				                    			<h6 className="text-white">Montant envoyé</h6>
				                    			<p className="text-white"><b>{parseFloat(transaction.sendingAmount).toLocaleString('fr-FR', { 
				                    				minimumFractionDigits: 2,
				                    				maximumFractionDigits: 2,
				                    			})} {transaction.sendingCurrency}</b></p>
				                    			<h6 className="text-white">Frais</h6>
				                    			<p className="text-white"><b>{parseFloat(transaction.commission).toLocaleString('fr-FR', { 
				                    				minimumFractionDigits: 2,
				                    				maximumFractionDigits: 2,
				                    			})} {transaction.sendingCurrency}</b></p>
				                    			<h6 className="text-white">Montant envoyé au destinataire</h6>
				                    			<p className="text-white"><b>{parseFloat(transaction.sendingAmountWithoutCommission).toLocaleString('fr-FR', { 
				                    				minimumFractionDigits: 2,
				                    				maximumFractionDigits: 2,
				                    			})} {transaction.sendingCurrency}</b></p>
				                    		</div>
				                    	</div>
				                    </div>
				                </div>
				            </div>

				            <div className="col-md-6">
								<div className="tab-content">
				                    <div className="tab-pane fade show active" id="about-paylio" role="tabpanel" aria-labelledby="about-paylio-tab">
				                    	<div className="right-item">
				                    		<div className="accordion transaction-details" id="accordionAbout-paylio">
				                    			<h6 className="section-title text-white">Destinataire</h6>

				                    			<h6 className="text-white">Nom</h6>
				                    			<p className="text-white"><b>{transaction.receiver.first_name}</b></p>
				                    			<h6 className="text-white">Prénom</h6>
				                    			<p className="text-white"><b>{transaction.receiver.last_name}</b></p>
				                    			<h6 className="text-white">Téléphone du destinataire</h6>
				                    			<p className="text-white"><b>{transaction.receiver.tel}</b></p>
				                    			<h6 className="text-white">Adresse e-mail du destinataire</h6>
				                    			<p className="text-white"><b>{transaction.receiver.email}</b></p>
				                    			<h6 className="text-white">Pays de destination</h6>
				                    			<p className="text-white"><b>{transaction.receivingCountry}</b></p>
				                    			<h6 className="text-white">Montant à percevoir</h6>
				                    			<p className="text-white"><b>{parseFloat(transaction.receivingAmount).toLocaleString('fr-FR', { 
				                    				minimumFractionDigits: 2,
				                    				maximumFractionDigits: 2,
				                    			})} {transaction.receivingCurrency}</b></p>

				                    		</div>
				                    	</div>
				                    </div>
				                </div>
				            </div>

				            {loggedUser && loggedUser.is_admin === true ?
					            <div className="col-md-12 margin-top">
									<div className="tab-content">
					                    <div className="tab-pane fade show active" id="about-paylio" role="tabpanel" aria-labelledby="about-paylio-tab">
					                    	<div className="right-item">
					                    		<div className="accordion" id="accordionAbout-paylio">
					                    			<h6 className="section-title text-white">Admin</h6>
					                    			{transaction.transactionMadeByAdmin ? 
															<div className="success-text">
																Argent envoyé par l'admin le&nbsp;
																{new Date(transaction.transactionMadeByAdminOn).toLocaleString('fr-FR', {
												                    year: 'numeric',
												                    month: 'long',
												                    day: 'numeric',
												                    hour: 'numeric',
												                    minute: 'numeric',
												                    second: 'numeric'
												                })}
															</div> 
															:
															<div className="warning-text">Argent pas encore envoyé par l'admin</div> 
													}<br/>

					                    			<h6 className="text-white">Montant payé par l'expéditeur en Euro</h6>
					                    			<p className="text-white"><b>{parseFloat(transaction.sendingAmountInCompanyCurrency).toLocaleString('fr-FR', { 
					                    				minimumFractionDigits: 2,
					                    				maximumFractionDigits: 2,
					                    			})} Euro</b></p>
						                    			<h6 className="text-white">Frais en Euro</h6>
						                    			<p className="text-white"><b>{parseFloat(transaction.commissionInCompanyCurrency).toLocaleString('fr-FR', { 
					                    				minimumFractionDigits: 2,
					                    				maximumFractionDigits: 2,
					                    			})} Euro</b></p>
						                    			<h6 className="text-white">Montant envoyé au destinataire en Euro</h6>
						                    			<p className="text-white"><b>{parseFloat(transaction.amountWithNoCommissionToSendInCompanyCurency).toLocaleString('fr-FR', { 
					                    				minimumFractionDigits: 2,
					                    				maximumFractionDigits: 2,
					                    			})} Euro</b></p>


						                    		{transaction.transactionSuccessful ? 

						                    			<p>
						                    				<br/>
							                    			{transaction.transactionMadeByAdmin ? null : <> <textarea placeholder="Détails de la transaction" value={ transactionMadeByAdminDetails } onChange={e=>setTransactionMadeByAdminDetails(e.target.value)} rows="5" cols="33"></textarea> <button className="money-sent-by-admin-button" onClick={ e=>updateStatus('moneySentByAdmin', 'Avez-vous déjà envoyé l\'argent au bénéficiaire ?') }>Argent envoyé par l'admin</button> </> }
							                    			{transaction.transactionWithdrawn ? null : <button className="money-received-button" onClick={ e=>updateStatus('moneyWithdrawn', 'Le bénéficiaire a-t-il déjà perçu l\'argent envoyé ?') }>Argent perçu</button> }
							                    			<br/><br/>
							                    		</p> 
							                    	: ''

							                    	}

					                    		</div>
					                    	</div>
					                    </div>
					                </div>
					            </div>
					        : null }
				        </>
				    ))}
		        </>
	        }
        </>
	)
}

