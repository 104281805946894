import './userprofile.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { Circles } from  'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

import SingleUser from '../../components/singleuser/singleuser';

export default function UserProfile() {

	const queryParams = new URLSearchParams(window.location.search);
	const param = queryParams.get('param');

    return(
        
        <>
            <div className="top-section">.</div>
        	<section aria-label="section">
                <div className="container">
                    <div className="row">
                        <SingleUser userid ={ param } />
                    </div>
                </div>
            </section>   
        </>

    )
}