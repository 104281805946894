import './App.css';

import Home from './pages/home/home';
import About from './pages/about/about';
import Login from './pages/login/login';
import Header from './components/headers/header';
import CreateAccount from './pages/createaccount/createaccount';
import TransactionsList from './pages/transactionslist/transactionslist';
import TransactionDetails from './pages/transactiondetails/transactiondetails';
import Contact from './pages/contact/contact';
import UserProfile from './pages/userprofile/userprofile';
import ChangePassword from './pages/changepassword/changepassword';
import EditUser from './pages/edituser/edituser';
import Users from './pages/users/users';

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const currentUrl = window.location.pathname;
  let showHeader = true;

  if((currentUrl === '/connection')||(currentUrl === '/creer-un-compte')) {
    showHeader = false;
  }

  console.log('\n\n');
  console.log(currentUrl);
  console.log('\n\n');
  

  return (
    <Router>

      {showHeader ? <Header/> : ""}

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/a-propos" element={<About />} />
        <Route path="/connection" element={<Login />} />
        <Route path="/creer-un-compte" element={<CreateAccount />} />
        <Route path="/transactions" element={<TransactionsList />} />
        <Route path="/transaction" element={<TransactionDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/membre" element={<Users />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/changer-mot-de-passe" element={<ChangePassword />} />
        <Route path="/editer-utilisateur" element={<EditUser />} />
      </Routes>

      <ToastContainer />

    </Router>
  )
}

export default App;
