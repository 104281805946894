import './singleuser.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function SingleUser({ userid }) {

	const queryParams = new URLSearchParams(window.location.search);
	const param = queryParams.get('param');

	const [userData, setUserData] = useState([]);
    const [loadUsers, setLoadUsers] = useState("true");

    const loggedUser = useSelector(state=>state.user.user);

	useEffect(()=> {

		const getUserData = async()=> {
			try {
				const user_data = await axios.get(process.env.REACT_APP_API_KEY+'/users/'+userid);
				setUserData(user_data.data);

				setLoadUsers("false");
			}
			catch(err) {
				toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
			}
		}

		// functions call

		getUserData();

	}, []);

	return (

		<section aria-label="section">
            <div className="container">
                <div className="row">

                	{loadUsers === 'true' ? 
		                <div className="data-loader">
		                    <TailSpin 
		                        height="40"
		                        width="40"
		                        color="#000"
		                        ariaLabel="tail-spin-loading"
		                        radius="1"
		                        wrapperStyle={{}}
		                        wrapperclassName=""
		                        visible={true}
		                    />
		                </div> : "" 
		            }

                	<div className="container">
					    <div className="main-body">
					        <div className="row gutters-sm">
					            <div className="col-md-4 mb-3">
					              <div className="black-background">
					                <div className="card-body">
					                  <div className="d-flex flex-column align-items-center text-center">
					                  	{ userData.profile_image ? <img className="rounded-circle" src={ userData.profile_image } alt="" width="150" /> : <img src="/images/misc/avatar.png" className="rounded-circle" alt="" width="150" /> }
					                    <div className="mt-3">
					                      <h5 className="text-white">{ userData.title } { userData.first_name } { userData.last_name }</h5>
					                      <br/> 

					                      {loggedUser && loggedUser.slug === param && (
											<Link to="/changer-mot-de-passe" className="full-button">Changer de mot de passe</Link>
											    
										   )}

					                    </div>
					                  </div>
					                </div>
					              </div>

					            </div>
					            <div className="col-md-8">
					              <div className="black-background mb-3">
					                <div className="card-body">
					                  
					                  <div className="row">
					                    <div className="col-sm-3">
					                      <h6 className="mb-0 green">Email</h6>
					                    </div>
					                    <div className="col-sm-9 text-secondary text-white">
					                      { userData.email }
					                    </div>
					                  </div>
					                  <hr/>

					                  <div className="row">
					                    <div className="col-sm-3">
					                      <h6 className="mb-0 green">Tel</h6>
					                    </div>
					                    <div className="col-sm-9 text-secondary text-white">
					                      { userData.tel }
					                    </div>
					                  </div>
					                  <hr/>

					                  <div className="row">
					                    <div className="col-sm-12">
					                    	{loggedUser && (loggedUser.slug === param || loggedUser.is_admin) && (
											    <div className="col-md-2 text-lg-right text-sm-center">
											        <Link to={`/editer-utilisateur/?param=${userData._id}`} className="clear-button">Editer </Link>
											    </div>
											)}

					                    </div>
					                  </div>
					                </div>
					              </div>

					            </div>
					          </div>

					        </div>
					    </div>

                </div>
            </div>
        </section>
        
	)

}
