import './createaccount.css';

import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginFailure, loginStart, loginSuccess } from '../../redux/userSlice';

export default function CreateAccount() {

    const navigate = useNavigate();

    const loggedUser = useSelector(state=>state.user.user);

    if(loggedUser) {
        window.location.href = "/";
        toast.info("Vous êtes déjà enregistré(e) !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
    }
    
    const [load, setLoader] = useState('');
    const [first_name, setFName] = useState("");
    const [last_name, setLName] = useState("");
    const [email, setEmail] = useState("");
    const [tel, setTel] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const dispatch = useDispatch();

    const handleAccountCreation = async(e) => {
        e.preventDefault();

        if((first_name == "")||(last_name == "")||(email == "")||(tel == "")||(password == "")||(passwordConfirmation == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            if(passwordConfirmation != password)
            {
                toast.error("Les deux mots de passe ne correspondent pas. Veuillez vérifier votre mot de passe et réessayer !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            else
            {
                setLoader(true);

                try{

                    const signUpRes = await axios.post(process.env.REACT_APP_API_KEY+"/auth/sign-up", {first_name, last_name, email, tel, password, passwordConfirmation});

                    setFName("");
                    setLName("");
                    setEmail("");
                    setTel("");
                    setPassword("");
                    setPasswordConfirmation("");

                    setLoader(false);

                    navigate('/connection');

                    toast.success(signUpRes.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
                catch(err) {
                    setLoader(false);
                    toast.error("Erreur de création de compte : "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
            }
        }

    }

    return(
        <>
            <div id="loading">
              <div className="loader simple-loader">
                  <div className="loader-body"></div>
              </div>    </div>
              <div >  
                <div className="wrapper">
                    <div className="auth-bg">
                       <section className="login-content">
                            <div className="row m-0 align-items-center vh-100"> 
                                <div className="col-lg-4 res-hide">
                                    <div className="sign-up-img2">
                                        <img src="images/big-logo.png" />      
                                    </div>
                                </div>           
                                <div className="col-md-12 col-lg-8">
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <div className=" form-card d-flex justify-content-center mb-0 black-background">
                                                <div className="card-body">
                                                    <div className="auth-form">
                                                        <h2 className="text-center mb-4 stroke-title">Créer un compte</h2>
                                                            <div className="row">
                                                                <div className="col-md-12 mb-4">
                                                                    <div className="form-floating">
                                                                        <input type="text" className="form-control form-field" placeholder="Votre nom" value={ first_name } onChange={e=>setFName(e.target.value)}/>
                                                                        <label for="lastName">Nom</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 mb-4">
                                                                    <div className="form-floating mb-3">
                                                                        <input type="text" className="form-control" placeholder="Votre Prénom" value={ last_name } onChange={e=>setLName(e.target.value)}/>
                                                                        <label for="lastName">Prénom</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 mb-4">
                                                                    <div className="form-floating mb-3">
                                                                            <input type="email" className="form-control" placeholder="Votre Adresse E-mail" value={ email } onChange={e=>setEmail(e.target.value)}/>
                                                                            <label for="floatingInput">Email</label>
                                                                        </div>
                                                                </div>
                                                                <div className="col-md-12 mb-4">
                                                                    <div className="form-floating mb-3">
                                                                        <input type="text" className="form-control" placeholder="Votre Numéro de Téléphone" value={ tel } onChange={e=>setTel(e.target.value)}/>
                                                                        <label for="phoneno">Numéro de Téléphone</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-12 mb-4">
                                                                    <div className="form-floating mb-2">
                                                                            <input type="password" className="form-control" placeholder="Mot De Passe" value={ password } onChange={e=>setPassword(e.target.value)}/>
                                                                            <label for="Password">Mot De Passe</label>
                                                                        </div>
                                                                </div>
                                                                <div className="col-md-12 mb-4">
                                                                    <div className="form-floating mb-2">
                                                                            <input type="password" className="form-control" placeholder='Confirmez votre Mot de Passe' value={ passwordConfirmation } onChange={e=>setPasswordConfirmation(e.target.value)}/>
                                                                            <label for="confirmpassword">Confirmez votre Mot de Passe</label>
                                                                        </div>
                                                                </div>
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="text-white small-text">En vous inscrivant, vous confirmez que vous avez lu et accepté nos <a href="" className="text-white small-text terms-and-conditions">termes et conditions</a>.</label>
                                                            </div>
                                                            <div className="text-center mb-4">
                                                                {load === true ? 
                                                                    <div className="loader-spin">
                                                                        <Circles
                                                                                height="50"
                                                                                width="50"
                                                                                color="#0f0"
                                                                                ariaLabel="circles-loading"
                                                                                wrapperStyle={{}}
                                                                                wrapperClass=""
                                                                                visible={true}
                                                                        />
                                                                    </div>
                                                                :
                                                                    <button onClick={ handleAccountCreation } className="signup-button">Sign Up</button>
                                                                }
                                                            </div>
                                                            <div className="centered">
                                                                <Link to="/connection" className="text-green small-text terms-and-conditions">J'ai déjà un compte. Je me Connecte !</Link>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </section>
                    </div> 
                </div>
            </div>
        </>
    )
}
