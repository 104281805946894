import './contact.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Circles } from  'react-loader-spinner';
import { useSelector } from 'react-redux';
import CKeditor from '../../components/CKeditor';

import { companyDataFetchStart, companyDataFetchSuccess, companyDataFetchFailure } from '../../redux/companySlice';

export default function Contact() {

    const [company_details, setCompanyData] = useState([]);
    const [ full_name, setFullName ] = useState("");
    const [ tel, setTel ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ subject, setSubject ] = useState("");
    const [ message, setMessage ] = useState("");

    const [editorLoaded, setEditorLoaded] = useState(false);
    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);
    const stored_host_company_data = useSelector(companyDataFetchSuccess);

    useEffect(()=>{

        window.scrollTo(0, 0);
        setEditorLoaded(true);

        const getCompanyData = async()=>{
            const companyRes = await axios.get(process.env.REACT_APP_API_KEY+"/company/?host_organization=true");
            setCompanyData(companyRes.data);
        }

        if (Object.keys(stored_host_company_data.payload.companyData.companyData).length === 0) {
            getCompanyData();          
        }
        else
        {
            setCompanyData(stored_host_company_data.payload.companyData.companyData);
        }

    }, []);

    const sendMessage = async(e) => {
        e.preventDefault();

        if((full_name == "")||(tel == "")||(email == "")||(subject == "")||(message == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            toast.info("Envoi du message en cours, veuillez patienter ...", { position: toast.POSITION.TOP_RIGHT, autoClose: 5000, toastId: 'toast2' });

            setLoader(true);

            try{

                const message_sent_data = await axios.post(process.env.REACT_APP_API_KEY+"/messages", {full_name, tel, email, subject, message});

                setFullName("");
                setTel("");
                setEmail("");
                setSubject("");
                setMessage("");

                setLoader(false);

                toast.success(message_sent_data.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            catch(err) {
                setLoader(false);
                toast.error("Une erreur est survenue !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>
            <section className="location">
                <div className="overlay pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-8">
                                <div className="section-header text-center">
                                    <h4 className="title green">Nous contacter</h4>
                                    {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit dondimentum.</p> */}
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center cus-mar">
                            <div className="col-xl-5 col-md-6">
                                <div className="single-box">
                                    Didi est une plateforme déployée par <a target="_blank" href="https://bakuritechnologies.fr/">bakuri technologies</a>, une société informatique établie à Paris, en France. Pour toute question ou suggestion, n'hésitez pas à nous contacter en utilisant le formulaire de contact ci-dessous.
                                </div>
                                {company_details?.map((company)=>(
                                    <div className="single-box">
                                        <div className="email-phone">
                                            <div className="img-box">
                                                <img src="images/email.png" alt="icon"/>
                                            </div>
                                            <p>{ company.tel }</p>
                                        </div>
                                        <div className="email-phone">
                                            <div className="img-box">
                                                <img src="images/telephone.png" alt="icon"/>
                                            </div>
                                            <p>{ company.email }</p>
                                        </div>
                                        <div className="email-phone">
                                            <div className="img-box">
                                                <img src="images/adresse.png" alt="icon"/>
                                            </div>
                                            <p>{ company.address }</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-xl-7 col-md-6">
                                <div className="single-box">
                                    
                                    <div className="contact-form">
                                        <label>Votre nom complet <span className="req">*</span></label>
                                        <input type="text" className="input" value={ full_name } onChange={ e=>setFullName(e.target.value) } />
                                        <label>Votre adresse email <span className="req">*</span></label>
                                        <input type="email" className="input" value={ email } onChange={ e=>setEmail(e.target.value) } />
                                        <label>Votre numéro de téléphone <span className="req">*</span></label>
                                        <input type="text" className="input" value={ tel } onChange={ e=>setTel(e.target.value) } />
                                        <label>Objet <span className="req">*</span></label>
                                        <input type="text" className="input" value={ subject } onChange={ e=>setSubject(e.target.value) } />
                                        <label>Message <span className="req">*</span></label>
                                        {/*<textarea cols="10" rows="10" className="area-input" value={ message }  onChange={ e=>setMessage(e.target.value) } ></textarea>*/}

                                        <CKeditor
                                            name="message"
                                            onChange={(message) => {
                                              setMessage(message);
                                            }}
                                            editorLoaded={editorLoaded}
                                        />
                                        <br/>
                                        
                                        {load === true ?
                                            <a className="posting-load">
                                                <Circles 
                                                    height="40"
                                                    width="40"
                                                    color="#000"
                                                    ariaLabel="circles-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                />
                                            </a> : <button className="cmn-btn" onClick={ sendMessage }>Envoyer</button>   
                                            
                                        }

                                    </div>
                                

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </>

    )
}