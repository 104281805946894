
import './transactionslist.css';

import TransactionsListCpnt from '../../components/transactionslist/transactionslist';

import axios from 'axios';
import { Link, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { LineWave } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

export default function TransactionsList() {

    const [categories_data, setCategoriesData] = useState([]);
    const [search_term, setSearchTerm] = useState("");
    const [category_search, setCategorySearch] = useState("");
    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=>{

        const getCategoriesData = async()=>{
            const categoriesRes = await axios.get(process.env.REACT_APP_API_KEY+"/article-categories/");
            setCategoriesData(categoriesRes.data);
        }

        const getCategoryDetails = async(category_id)=>{
            const categoriesRes = await axios.get(process.env.REACT_APP_API_KEY+"/article-categories/"+category_id);
            setCategoriesData(categoriesRes.data);
        }

        // functions call 

        getCategoriesData();

    }, []);

    const filter = async(e) => {
        e.preventDefault();

        setLoader(true);

        try{
            setSearchTerm("");
            setCategorySearch("");
            setLoader(false);
        }
        catch(err) {
            setLoader(false);
            toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
        }

    }

	return(
		<>
		    <section class="current-openings">
		        <div class="overlay pt-120 pb-120">
		            <div class="container wow fadeInUp">
		                <div class="row justify-content-center justify-content-between">
		                    
		                    <div class="col-xl-6">
		                        <div class="tab-content">
		                            <div class="tab-pane fade show active" id="all-jobs" role="tabpanel" aria-labelledby="all-jobs-tab">
		                                <div class="section-header">
			                                <h5 class="title green">Mes Transactions</h5>
			                            </div>

			                            <TransactionsListCpnt search_term={search_term} limit={9} />

		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		    </section>
		</>
	)

}