import './changepassword.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Circles } from  'react-loader-spinner';

export default function ChangePassword() {

    const [ old_password, setOldPassword ] = useState("");
    const [ new_password, setNewPassword ] = useState("");
    const [ new_password_confirmation, setNewPasswordConfirmation ] = useState("");

    const [load, setLoader] = useState('');

    const loggedUser = useSelector(state=>state.user.user);

    const updatePassword = async(e) => {
        e.preventDefault();

        if((old_password == "")||(new_password == "")||(new_password_confirmation == "")) {
            toast.warning("Veuillez remplir tout le formulaire avant de poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        else
        {
            if(new_password != new_password_confirmation)
            {
                toast.error("Les deux mots de passe ne correspondent pas. Veuillez vérifier votre mot de passe et réessayer !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
            else
            {
                setLoader(true);

                try{

                    const password_update_data = await axios.post(process.env.REACT_APP_API_KEY+"/auth/"+loggedUser.slug, {old_password, new_password}, {
                        headers: {
                            Authorization: loggedUser.token
                        }
                    });

                    setOldPassword("");
                    setNewPassword("");
                    setNewPasswordConfirmation("");

                    setLoader(false);

                    toast.success(password_update_data.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
                catch(err) {
                    setLoader(false);
                    toast.error("Une erreur est survenue ! "+err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
                }
            }

        }

    }

    return(
        
        <>
            <div className="top-section">.</div>

            {loggedUser ?
            
                <section aria-label="section">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-8">
                                <h2 className="account-creation-title">Changer mon mot de passe</h2>
                                <div className="account-creation-form">
                                    <input className='input' type="password" placeholder='Ancien Mot De Passe *' value={ old_password } onChange={e=>setOldPassword(e.target.value)}/>
                                    <input className='input' type="password" placeholder='Nouveau Mot De Passe *' value={ new_password } onChange={e=>setNewPassword(e.target.value)}/>
                                    <input className='input' type="password" placeholder='Confirmer Nouveau Mot De Passe *' value={ new_password_confirmation } onChange={e=>setNewPasswordConfirmation(e.target.value)}/>
                                    
                                    {load === true ?
                                        <a className="posting-load">
                                            <Circles 
                                                height="40"
                                                width="40"
                                                color="#000"
                                                ariaLabel="circles-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        </a> : <button className="submit-button" onClick={ updatePassword }>Mettre à jour</button>   
                                        
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                :

                <div className="kode-content">
                    <section className="kode-about-us-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 success-message">
                                    Vous n'êtes pas connecté(e) ! Veuillez vous connecter pour acceder cette section !
                                </div> 
                            </div>
                        </div> 
                    </section>
                </div> 
                
            }
   
        </>

    )
}