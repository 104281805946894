import './transactionslist.css';

import axios from 'axios';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from 'react-redux';
import { TailSpin, RotatingLines } from  'react-loader-spinner';

export default function TransactionsListCpnt({ search_term, limit }) {

    const [transactionsData, setTransactionsData] = useState([]);
    const [loadTransactions, setLoadTransactions] = useState("true");

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=> {

        const getTransactionsData = async()=> {

            try {
                const transactions = await axios.get(process.env.REACT_APP_API_KEY+'/transaction', {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });
                setTransactionsData(transactions.data);

                setLoadTransactions("false");
            }
            catch(err) {
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call

        getTransactionsData();
        
    });

	return (
    	<>
            {loadTransactions === 'true' ? 
                <div className="data-loader">
                    <TailSpin 
                        height="40"
                        width="40"
                        color="#000"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperclass=""
                        visible={true}
                    />
                </div>

                : "" 
            }

            {transactionsData.length === 0 ? <div className="no-data">Pas de donnée trouvée !</div> : 
                
                <>
                    {Array.isArray(transactionsData) && transactionsData.map((transaction) => (

                        <div className="single-box d-flex align-items-center justify-content-between">

                            <div className="left-box">

                                {transaction.transactionSuccessful ? (
                                    transaction.transactionWithdrawn ? (
                                        <div className="money-withdrawn-by-user">
                                            Argent perçu le&nbsp;
                                            {new Date(transaction.transactionWithdrawnOnDateTime).toLocaleString('fr-FR', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: 'numeric',
                                                minute: 'numeric',
                                                second: 'numeric'
                                            })}
                                        </div>
                                    ) : (
                                        <div className="money-not-withdrawn-by-user">
                                            Argent pas encore perçu
                                        </div>
                                    )
                                ) : (
                                    <>
                                        <h6 className="important-color">Argent pas encore envoyé par l'expéditeur.</h6>
                                        <br/>
                                    </>
                                )}

                                <Link to={{pathname: '/transaction/', search: '?transaction='+transaction._id}}><h6 className="text-white">CODE: {transaction.transactionCode}</h6></Link>
                                <ul>
                                    <li className="text-white">{transaction.receiver.first_name} {transaction.receiver.last_name}</li>
                                </ul>
                                <div className="salary-limit">
                                    <p className="text-white">{parseFloat(transaction.sendingAmount).toLocaleString('fr-FR', { 
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        })} {transaction.sendingCurrency}</p>
                                </div>
                            </div>
                            <div className="right-box">
                                <Link to={{pathname: '/transaction/', search: '?transaction='+transaction._id}}><img className="arrow-image" src="images/arrow-right-white.png" alt="image"/></Link>
                            </div>
                        </div>
                    ))} 
                </>
            }
        </>
    )
}