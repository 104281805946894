import './transactiondetails.css';

import axios from 'axios';
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Circles } from  'react-loader-spinner';
import { useSelector } from 'react-redux';

import TransactionData from '../../components/transactiondata/transactiondata';

export default function TransactionDetails() {

    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    const transaction = queryParams.get('transaction');
    const update_payment = queryParams.get('update_payment');

    const loggedUser = useSelector(state=>state.user.user);

    return(
        
        <>
            <section className="faqs-section help-category">
                <div className="overlay pt-120 pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row justify-content-center justify-content-between">
                            <TransactionData transaction_status={ status } transaction_id={ transaction } update_payment={ update_payment } />
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}