import './header.css';

import axios from 'axios';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { loginFailure, loginStart, loginSuccess } from '../../redux/userSlice';
import { companyDataFetchStart, companyDataFetchSuccess, companyDataFetchFailure } from '../../redux/companySlice';

export default function Header() {

    const dispatch = useDispatch();

    const [company_details, setCompanyData] = useState([]);
    const [law_categories_data, setLawCategoriesResData] = useState([]);

    const loggedUser = useSelector(state=>state.user.user);

    useEffect(()=>{

        const loadCompanyInitialState = async()=> {

            dispatch(companyDataFetchStart());

            try{
                const companyRes = await axios.get(process.env.REACT_APP_API_KEY+"/company/?host_organization=true");
                dispatch(companyDataFetchSuccess(companyRes.data));
                setCompanyData(companyRes.data);
            }
            catch(err) {
                dispatch(companyDataFetchFailure());
                toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
            }
        }

        // functions call 

        // loadCompanyInitialState();

    }, []);

    const logout = async()=> {

        try{
            const logoutRes = await axios.put(process.env.REACT_APP_API_KEY+"/auth/logout/", null, {
              headers: {
                Authorization: loggedUser.token
              }
            });

            dispatch(loginSuccess());

            toast.success(logoutRes.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }
        catch(err) {
            toast.error(err.response.data, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
        }

    }

    const [mobileMenuShow, setMobileMenuShow] = useState(false);

    const handleMenuBtnClick = () => {
        const headerElement = document.querySelector('.mobile-menu');
        headerElement.classList.toggle('show');
    };

    return(
        <>
            <header className="header-section">
                <div className="overlay">
                    <div className="container">
                        <div className="row d-flex header-area">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <Link className="navbar-brand" to="/">
                                    <img src="images/logo.png" className="logo" alt="logo"/>
                                </Link>
                                
                                <button className='mobile-menu-button' onClick={handleMenuBtnClick}><img className='menu-img' src="images/menu.png" alt="menu"/></button>

                                <div className="collapse navbar-collapse justify-content-end" id="navbar-content">
                                    <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                                        <li className="nav-item dropdown main-navbar">
                                            <Link className="nav-link" to='/'>Envoyer de l'argent</Link>
                                        </li>
                                        <li className="nav-item dropdown main-navbar">
                                            <Link className="nav-link" to="/transactions">Mes transactions</Link>
                                        </li>
                                        <li className="nav-item dropdown main-navbar">
                                            <Link className="nav-link" to={`/profile/?param=${loggedUser.slug}`}>Mon compte</Link>
                                        </li>
                                        <li className="nav-item dropdown main-navbar">
                                            <Link className="nav-link" to="/contact">Nous contacter</Link>
                                        </li>
                                    </ul>
                                    <div className="right-area header-action d-flex align-items-center max-un">
                                        <Link to={`/profile/?param=${loggedUser.slug}`}>Bonjour, { loggedUser.last_name }</Link>
                                        <Link className="cmn-btn danger-button" onClick={logout} to="/"> <img src="images/turn-off.png" className="signout-icon" alt="logo"/> </Link>
                                    </div>
                                </div>
                            </nav>

                            <ul className='mobile-menu'>
                                <li><Link to='/'>Envoyer de l'argent</Link></li>
                                <li><Link to='/transactions'>Mes transactions</Link></li>
                                <li><Link to={`/profile/?param=${loggedUser.slug}`}>Mon compte</Link></li>
                                <li><Link to='/contact'>Nous contacter</Link></li>
                            </ul>


                            





                        </div>
                    </div>
                </div>
            </header>  
        </>
    )
}