import './makepayment.css';

import axios from 'axios';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import { Circles } from  'react-loader-spinner';
import CKeditor from '../../components/CKeditor';

export default function MakePayment() {

    const [ countries, setCountries ] = useState([]);
    const [ sending_amount, setSendingAmount ] = useState("");
    const [ sending_country, setSendingCountry ] = useState("");
    const [ sending_currency, setSendingCurrency ] = useState("");
    const [ receiving_amount, setReceivingAmount ] = useState("");
    const [ receiving_country, setReceivingCountry ] = useState("");
    const [ receiving_currency, setReceivingCurrency ] = useState("");
    const [ one_sending_money_to_receiving_money, setOneSendingMoneyToReceivingMoney ] = useState("");
    const [ one_receiving_money_to_sending_money, setOneReceivingMoneyToSendingMoney ] = useState("");
    const [ company_currency_rate_to_sending_currency, setCompanyCurrencyRateToSendingCurrency ] = useState("");
    const [ sending_currency_rate_to_company_currency, setSendingCurrencyRateToCompanyCurrency ] = useState("");
    const [ company_commission, setCompanyCommission ] = useState("");
    const [ commission_in_company_currency, setCommissionInCompanyCurrency ] = useState("");
    const [ company_currency_rate_to_receiving_currency, setCompanyCurrencyRateToReceivingCurrency ] = useState("");
    const [ receiving_currency_rate_to_company_currency, setReceivingCurrencyRateToCompanyCurrency ] = useState("");
    const [ company_commission_in_receiving_currency, setCompanyCommissionInReceivingCurrency ] = useState("");
    const [ commission_user_currency, setCommissionUserCurrency ] = useState("");
    const [ net_to_pay, setNetToPay ] = useState("");
    const [ receiver_tel, setReceiverTel ] = useState("");
    const [ receiver_email, setReceiverEmail ] = useState("");
    const [ receiver_fname, setReceiverFname ] = useState("");
    const [ receiver_lname, setReceiverLname ] = useState("");
    const [ money_sending_load, setMoneySendingLoader ] = useState(false);
    const [ show_net_pay, setShowNetPay ] = useState(false);

    const loggedUser = useSelector(state=>state.user.user);

    if(!loggedUser) {
        window.location.href = "/connection";
        toast.info("Veuillez vous connecter !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast1' });
    }

    const getConversionRate = (fromCurrency, toCurrency) => {
      return new Promise(async (resolve, reject) => {

        if (fromCurrency === toCurrency) {
            resolve(1);
        }
        else
        {
            const savedRate = await axios.get(process.env.REACT_APP_API_KEY+"/daily-exchange-rate/?fromCurrency="+fromCurrency+"&toCurrency="+toCurrency);

            if (savedRate.data.length === 0) {

                try {
                  const data_post = await axios.get("https://api.apilayer.com/exchangerates_data/latest?symbols="+toCurrency+"&base="+fromCurrency, {headers: { apikey: "JZ11h3e4QPzVn5Hm2RC8yRjND021a4Ds" }});

                  if (data_post.data.success === true) {

                    const rate = data_post.data.rates[toCurrency];

                    const today = new Date();
                    const year = today.getFullYear();
                    const month = today.getMonth() + 1;
                    const day = today.getDate();

                    const date = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

                    axios.post(process.env.REACT_APP_API_KEY+"/daily-exchange-rate", {fromCurrency, toCurrency, rate, date}, {
                            headers: {
                                Authorization: loggedUser.token
                            }
                        });

                    resolve(rate);
                  } else {
                    reject("Le taux de conversion n'a pas été récupéré !");
                  }

                } catch (error) {
                  console.error(error);
                }


                /*
                try {
                  const data_post = await axios.get(`http://api.currencylayer.com/live?access_key=7709ec42296aebdd679c6e8a88e562af&currencies=${toCurrency}&source=${fromCurrency}&format=1`);

                  if (data_post.data.success === true) {
                    const returnedQuote = data_post.data.quotes;
                    const keys = Object.keys(returnedQuote);
                    const exchangeKey = keys[0];
                    const rate = returnedQuote[exchangeKey];

                    const today = new Date();
                    const year = today.getFullYear();
                    const month = today.getMonth() + 1;
                    const day = today.getDate();

                    const date = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

                    axios.post(process.env.REACT_APP_API_KEY+"/daily-exchange-rate", {fromCurrency, toCurrency, rate, date}, {
                            headers: {
                                Authorization: loggedUser.token
                            }
                        });

                    resolve(rate);
                  } else {
                    reject("Le taux de conversion n'a pas été récupéré !");
                  }
                } catch (err) {
                  reject(err.message || "Une erreur s'est produite lors de la recherche du taux de conversion !");
                }
                */

            }
            else
            {
                resolve(savedRate.data[0].rate);
            }
        }

      });
    };

    const computeNetToPay = async( commissionInReceivingCurrency ) => {
        setNetToPay(receiving_amount-commissionInReceivingCurrency);
        setShowNetPay(true);
    }

    const getCommission = async(sendingAmount, sendingCurrency, companyCurrencyRateToSendingCurrency, sendingCurrencyRateToCompanyCurrency) => {

        const sendingAmountInCompanyCurrency = sendingAmount*sendingCurrencyRateToCompanyCurrency;

        const commission = await axios.get(process.env.REACT_APP_API_KEY+"/commission/?sendingAmountInCompanyCurrency="+sendingAmountInCompanyCurrency);

        const commissionValue = commission.data?.commission !== undefined ? commission.data.commission : (commission.data[0]?.commission ?? null);

        setCommissionInCompanyCurrency(commissionValue);
        setCompanyCommission(companyCurrencyRateToSendingCurrency*commissionValue);
        setCommissionUserCurrency(sendingCurrency);

        const commissionInReceivingCurrency = company_commission_in_receiving_currency*commissionValue;

        setCompanyCommissionInReceivingCurrency(commissionInReceivingCurrency);

        computeNetToPay(commissionInReceivingCurrency);
    }

    const processSendingAmount = async(sendingAmount) => {
        setSendingAmount(sendingAmount);

        if(one_sending_money_to_receiving_money === "") {

            if (receiving_currency === "") {
                Swal.fire("Attention !", "Veuillez indiquer le pays d'expédition et le pays de destination.", "warning");
                return;
            }

            try {
                const sendingRate = await getConversionRate(sending_currency, receiving_currency);

                setOneSendingMoneyToReceivingMoney(sendingRate);

                setReceivingAmount(sendingAmount * sendingRate);
            } catch (error) {
                toast.error(error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast2' });
            }
            
        }
        else
        {
            setReceivingAmount(sendingAmount*one_sending_money_to_receiving_money);
        }

        getCommission(sendingAmount, sending_currency, company_currency_rate_to_sending_currency, sending_currency_rate_to_company_currency);
    }

    const processSendingCurrency = async(sendingCountryCurrency) => {
        if (sendingCountryCurrency === "") {
            Swal.fire("Attention !", "Veuillez indiquer le pays d'expédition.", "warning");
            return;
        }

        const sendingCountryCurrencySplit = sendingCountryCurrency.split('-');
        setSendingCountry(sendingCountryCurrencySplit[0]);
        setSendingCurrency(sendingCountryCurrencySplit[1]);

        if (sendingCountryCurrencySplit[1] === 'EUR') {
            setCompanyCurrencyRateToSendingCurrency(1);
            setSendingCurrencyRateToCompanyCurrency(1);
        }
        else
        {
            const companyCurrencyRateToSendingCurrency = await getConversionRate('EUR', sendingCountryCurrencySplit[1]);
            setCompanyCurrencyRateToSendingCurrency(companyCurrencyRateToSendingCurrency);

            const sendingCurrencyToCompanyCurrencyRate = await getConversionRate(sendingCountryCurrencySplit[1], 'EUR');
            setSendingCurrencyRateToCompanyCurrency(sendingCurrencyToCompanyCurrencyRate);
        }

        if (receiving_currency === "") {
            Swal.fire("Attention !", "Veuillez indiquer le pays de destination.", "warning");
            return;
        }

        try {
            const sendingRate = await getConversionRate(sendingCountryCurrencySplit[1], receiving_currency);
            setOneSendingMoneyToReceivingMoney(sendingRate);

            if (sending_amount !== "") {
                setReceivingAmount(sending_amount * sendingRate);
            }
        } catch (error) {
            toast.error(error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast3' });
        }
    }

    const processReceivingAmount = async(receivingAmount) => {
        setReceivingAmount(receivingAmount);

        if(one_receiving_money_to_sending_money === "") {
            
            if (sending_currency === "") {
                Swal.fire("Attention !", "Veuillez indiquer le pays d'expédition et le pays de destination.", "warning");
                return;
            }

            try {
                const receivingRate = await getConversionRate(receiving_currency, sending_currency);
                setOneReceivingMoneyToSendingMoney(receivingRate);

                setSendingAmount(receivingAmount * receivingRate);
                
            } catch (error) {
                toast.error(error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast4' });
            }

        }
        else
        {
            setSendingAmount(receivingAmount*one_receiving_money_to_sending_money);
        }
    }

    const processReceivingCurrency = async(receivingCountryCurrency) => {
        if (receivingCountryCurrency === "") {
            Swal.fire("Attention !", "Veuillez indiquer le pays de destination.", "warning");
            return;
        }

        const receivingCountryCurrencySplit = receivingCountryCurrency.split('-');
        setReceivingCountry(receivingCountryCurrencySplit[0]);
        setReceivingCurrency(receivingCountryCurrencySplit[1]);

        if (sending_currency === 'EUR') {
            setCompanyCurrencyRateToReceivingCurrency(1);
            setReceivingCurrencyRateToCompanyCurrency(1);
        }
        else
        {
            const companyCurrencyRateToReceivingCurrency = await getConversionRate('EUR', receivingCountryCurrencySplit[1]);
            setCompanyCurrencyRateToReceivingCurrency(companyCurrencyRateToReceivingCurrency);

            const receivingCurrencyToCompanyCurrencyRate = await getConversionRate(receivingCountryCurrencySplit[1], 'EUR');
            setReceivingCurrencyRateToCompanyCurrency(receivingCurrencyToCompanyCurrencyRate);
        }

        if (sending_currency === "") {
            Swal.fire("Attention !", "Veuillez indiquer le pays d'expédition.", "warning");
            return;
        }

        try {
            const receivingRate = await getConversionRate(receivingCountryCurrencySplit[1], sending_currency);
            setOneReceivingMoneyToSendingMoney(receivingRate);

            if (receiving_amount !== "") {
                setSendingAmount(receiving_amount * receivingRate);
            }
        } catch (error) {
            toast.error(error, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast5' });
        }
    }

    const retrieveExistingUser = async(clientTelOrEmail) => {
        try
        {
            const retrieved_user = await axios.get(process.env.REACT_APP_API_KEY+'/users/?filter_by_email_or_tel='+clientTelOrEmail);
            if (retrieved_user.data.length !==0) {
                setReceiverFname(retrieved_user.data[0].first_name);
                setReceiverLname(retrieved_user.data[0].last_name);
                setReceiverTel(retrieved_user.data[0].tel);
                setReceiverEmail(retrieved_user.data[0].email);
            }
        }
        catch(err) {
            console.log(err);
        }
    }

    const sendMoney = async() => {
        if((sending_amount == "")||(sending_country == "")||(sending_currency == "")||(receiving_country == "")||(receiving_currency == "")||(company_commission == "")||(receiver_tel == "")||(receiver_email == "")||(receiver_fname == "")||(receiver_lname == "")) {
            toast.warning("Veuillez entrer toutes les informations pour poursuivre !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast6' });
        }
        else
        {
            setMoneySendingLoader(true);

            const sending_amount_in_company_currency = sending_amount*sending_currency_rate_to_company_currency;

            try{
                const money_send_data = await axios.post(process.env.REACT_APP_API_KEY+"/transaction", {sender: loggedUser.slug, sendingAmount: sending_amount, sendingCountry: sending_country, sendingCurrency: sending_currency, receivingAmount: receiving_amount, receivingCountry: receiving_country, receivingCurrency: receiving_currency, commission: company_commission, company_commission_in_receiving_currency, receiver_tel, receiver_email, receiver_fname, receiver_lname, commission_in_company_currency, sending_amount_in_company_currency, sending_currency_rate_to_company_currency}, {
                    headers: {
                        Authorization: loggedUser.token
                    }
                });

                if (!money_send_data) {
                    toast.error("Une erreur est survenue !", { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast7' });
                }
                else
                {
                    toast.success(money_send_data.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast8' });

                    window.location.href = money_send_data.data.payment_url;
                }

                setMoneySendingLoader(false);
            }
            catch(err) {
                setMoneySendingLoader(false);
                toast.error(err.response.data.message, { position: toast.POSITION.TOP_RIGHT, autoClose: 15000, toastId: 'toast9' });
            }
        }
    }

    useEffect(() => {
        const fetchCountries = async () => {
            const countriesData = await axios.get('https://didripay.com/countries.json'); // http://localhost:3000/countries.json
            setCountries(countriesData.data);
        };

        fetchCountries();
      }, []);

    return(
        <>
            <section className="banner-section index">
                <div className="overlay">
                    <div className="banner-content">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-xxl-8 col-xl-6 col-md-7">
                                    <div className="send-money">
                                        <h5 className="title padding-title green centered">Envoyer de l'argent</h5>
                                        <div className="currency-area">
                                                <div className="left-side">
                                                    {/* <span className="">Vous envoyez</span> */}
                                                    <input type="number" className="xlr transfer-input" placeholder="Montant à envoyez" value={ sending_amount } onChange={e=>processSendingAmount(e.target.value)}/>
                                                </div>
                                                <div className="right-side">
                                                    <select className="currency-select" onChange={e=>processSendingCurrency(e.target.value)}>
                                                        <option value="">Pays d'envoi</option>

                                                        {Array.isArray(countries) && countries.map((country) => (
                                                            <option value={`${country.country}-${country.currency_code}`}>{`${country.country} (${country.currency_code})`}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="calculation">
                                                <div className="calculation-details">
                                                    <div className="single-area d-flex align-items-center">
                                                        <div className="left-area">
                                                            <img src="images/commission-white.png" className="icon-img" alt="image"/>
                                                            <span className="mdr text-white"><b>{ company_commission } { commission_user_currency }</b></span>
                                                        </div>
                                                        <div className="right-area">
                                                            <span className="mdr green">Frais</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="currency-area">
                                                <div className="left-side">
                                                    {/* <span className="">Le destinataire recevra</span> */}
                                                    <input type="number" className="xlr transfer-input" placeholder="Montant à percevoir" value={ receiving_amount } onChange={e=>processReceivingAmount(e.target.value)}/>
                                                </div>
                                                <div className="right-side">
                                                    <select className="currency-select" onChange={e=>processReceivingCurrency(e.target.value)}>
                                                        <option value="">Pays de destination</option>
                                                        {Array.isArray(countries) && countries.map((country) => (
                                                            <option value={`${country.country}-${country.currency_code}`}>{`${country.country} (${country.currency_code})`}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            { show_net_pay ? 
                                                <div className="calculation margin-bottom">
                                                    <div className="calculation-details">
                                                        <div className="single-area d-flex align-items-center">
                                                            <div className="left-area">

                                                                {/*<span className="mdr text-white"><b>Veuillez noter que le destinataire recevra&nbsp; 
                                                                {parseFloat(net_to_pay).toLocaleString('fr-FR', { minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })} { receiving_currency } après déduction des frais d'envoi.</b></span>*/}

                                                                <span className="mdr green">
                                                                    Veuillez noter que la commission sera déduite du montant à envoyer. Le destinataire recevra donc moins que le montant indiqué à titre d'information.
                                                                </span>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ''
                                            }
                                            <div className="input-box">
                                                <input type="text" placeholder="Téléphone du destinataire" value={ receiver_tel } onChange={ e=>setReceiverTel(e.target.value) } onBlur={ e=>retrieveExistingUser(e.target.value) } />
                                            </div>
                                            <div className="input-box">
                                                <input type="email" placeholder="E-mail du destinataire" value={ receiver_email } onChange={ e=>setReceiverEmail(e.target.value) } onBlur={ e=>retrieveExistingUser(e.target.value) } />
                                            </div>
                                            <div className="input-box">
                                                <input type="text" placeholder="Nom du destinataire" value={ receiver_fname } onChange={ e=>setReceiverFname(e.target.value) } />
                                            </div>
                                            <div className="input-box">
                                                <input type="text" placeholder="Prénom du destinataire" value={ receiver_lname } onChange={ e=>setReceiverLname(e.target.value) } />
                                            </div>

                                            <div className="btn-area mt-40">
                                                {money_sending_load === true ? 

                                                    <div className="money-send-loader">
                                                        <Circles
                                                            height="40"
                                                            width="40"
                                                            color="#0f0"
                                                            ariaLabel="circles-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                        /> 
                                                        <p>Envoi en cours, veuillez patienter...</p>
                                                    </div>
                                                : 
                                                    <button className="cmn-btn w-100" onClick={ sendMoney }>Envoyer</button> 
                                                }
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}